import React from "react";
import "./Promo.scss";
import promoimg from "../../media/promo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";

const Promo = () => {
  const promo1 = [
    "Free PPN 50%-100%",
    "Free DP",
    "Free BPHTB",
    "Free AJB & PPJB",
    "Free Biaya KPR",
    "Free Internet 12 Bulan",
  ];
  const promo2 = [
    "Free Canopy",
    "Free Kitchen Set",
    "Voucher Furniture",
    "Voucher Diskon Umroh 2 Juta",
    "Voucher Hotel Bintang 5 Up To 3 Juta",
  ];

  return (
    <div id="promo" className="promocontainer">
      <hr className="divider" />
      <div className="promocenter">
        <img className="promoimg" alt="promokhi" src={promoimg} />
      </div>
      <hr className="divider" />
      <div className="promopoint">
        <div className="pointitle">Promo Terbaru :</div>
        <div className="poinitem">
          <div className="poinleft">
            {promo1.map((item, index) => (
              <div className="item">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="#086d44"
                  size="lg"
                />
                &nbsp; &nbsp;
                {item}
              </div>
            ))}
          </div>
          <div className="poinright">
            {promo2.map((item, index) => (
              <div className="item">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="#086d44"
                  size="lg"
                />
                &nbsp; &nbsp;
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
      <hr className="divider" />
    </div>
  );
};

export default Promo;
