import nismara1 from "./H-5.png";
import nismara2 from "./S__3981363_0.webp";
import nismara3 from "./S__3981362_0.webp";
import nismara4 from "./S__3981359_0.webp";
import nismara5 from "./S__3981358_0.webp";
import nismara6 from "./S__3981356_0.webp";
import nismara7 from "./S__3981354_0.webp";
import nismara8 from "./S__3981353_0.webp";
import nismara9 from "./S__3981352_0.webp";
import nismara10 from "./S__3981351_0.webp";
import nismara11 from "./S__3981350_0.webp";
import nismara12 from "./S__3981349_0.webp";
import nismara13 from "./S__3981348_0.webp";
import nismara14 from "./S__3981347_0.webp";
import nismara15 from "./S__3981345_0.webp";

const nismara = [
  nismara1,
  nismara2,
  nismara3,
  nismara4,
  nismara5,
  nismara6,
  nismara7,
  nismara8,
  nismara9,
  nismara10,
  nismara11,
  nismara12,
  nismara13,
  nismara14,
  nismara15,
];

export default nismara;
