import lavesh1 from "./H-1.png";
import lavesh2 from "./S__3153927-scaled.webp";
import lavesh3 from "./S__3153932-scaled.webp";
import lavesh4 from "./S__3153936-scaled.webp";
import lavesh5 from "./2.webp";
import lavesh6 from "./4.webp";
import lavesh7 from "./5.webp";
import lavesh8 from "./7.webp";
import lavesh9 from "./8.webp";
import lavesh10 from "./9.webp";

const lavesh = [
  lavesh1,
  lavesh2,
  lavesh3,
  lavesh4,
  lavesh5,
  lavesh6,
  lavesh7,
  lavesh8,
  lavesh9,
  lavesh10,
];

export default lavesh;
