import gallery1 from "./1.webp";
import gallery2 from "./2.webp";
import gallery3 from "./3-1.webp";
import gallery4 from "./4-1.webp";
import gallery5 from "./5-1.webp";
import gallery6 from "./6-1.webp";
import gallery7 from "./H-1.webp";
import gallery8 from "./H-3.webp";
import gallery9 from "./H-5.webp";
import gallery10 from "./H-10.webp";

const galleryimage = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
];

export default galleryimage;
