import fremont1 from "./H-6.webp";
import fremont2 from "./1-1.webp";
import fremont3 from "./2-3.webp";
import fremont4 from "./3-4.webp";
import fremont5 from "./4-4.webp";
import fremont6 from "./5-4.webp";
import fremont7 from "./6-3.webp";

const fremont = [
  fremont1,
  fremont2,
  fremont3,
  fremont4,
  fremont5,
  fremont6,
  fremont7,
];

export default fremont;
