import elora1 from "./H-4.webp";
import elora2 from "./2-4.webp";
import elora3 from "./3-5.webp";
import elora4 from "./4-5.webp";
import elora5 from "./5-5.webp";
import elora6 from "./6-4.webp";
import elora7 from "./7-4.webp";
import elora8 from "./8-4.webp";
import elora9 from "./9-3.webp";
import elora10 from "./10-3.webp";

const elora = [
  elora1,
  elora2,
  elora3,
  elora4,
  elora5,
  elora6,
  elora7,
  elora8,
  elora9,
  elora10,
];

export default elora;
