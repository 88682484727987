import Albasia1 from "./albasia (1).webp";
import Albasia2 from "./albasia (2).webp";
import Albasia3 from "./albasia (3).webp";
import Albasia4 from "./albasia (4).webp";
import Albasia5 from "./albasia (5).webp";
import Albasia6 from "./albasia (6).webp";
import Albasia7 from "./albasia (7).webp";
import Albasia8 from "./albasia (8).webp";
import Albasia9 from "./albasia (9).webp";

const albasia = [
  Albasia1,
  Albasia2,
  Albasia3,
  Albasia4,
  Albasia5,
  Albasia6,
  Albasia7,
  Albasia8,
  Albasia9,
];

export default albasia;
