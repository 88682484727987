import casajardin1 from "./H-8.webp";
import casajardin2 from "./4-3.webp";
import casajardin3 from "./5-3.webp";
import casajardin4 from "./6-2.webp";
import casajardin5 from "./7-3.webp";
import casajardin6 from "./8-3.webp";
import casajardin7 from "./9-2.webp";
import casajardin8 from "./10-2.webp";
import casajardin9 from "./11-2.webp";

const casajardin = [
  casajardin1,
  casajardin2,
  casajardin3,
  casajardin4,
  casajardin5,
  casajardin6,
  casajardin7,
  casajardin8,
  casajardin9,
];

export default casajardin;
