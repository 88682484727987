import s1 from "./s-1.webp";
import s2 from "./s-2.webp";
import s3 from "./s-3.webp";
import s4 from "./s-4.webp";
import s5 from "./s-5.webp";
import s6 from "./s-6.webp";
import s7 from "./s-7.webp";
import s8 from "./s-8.webp";
import s9 from "./s-9.webp";

const surroundingimg = [s1, s2, s3, s4, s5, s6, s7, s8, s9];

export default surroundingimg;
