import jasmia1 from "./H-10.webp";
import jasmia2 from "./2-5.webp";
import jasmia3 from "./3-6.webp";
import jasmia4 from "./4-6.webp";
import jasmia5 from "./6-5.webp";
import jasmia6 from "./7-5.webp";
import jasmia7 from "./9-2-1.webp";
import jasmia8 from "./10-5.webp";
import jasmia9 from "./11-4.webp";
import jasmia10 from "./12-2.webp";
import jasmia11 from "./13-2.webp";
import jasmia12 from "./14-1.webp";
import jasmia13 from "./15-1.webp";

const jasmia = [
  jasmia1,
  jasmia2,
  jasmia3,
  jasmia4,
  jasmia5,
  jasmia6,
  jasmia7,
  jasmia8,
  jasmia9,
  jasmia10,
  jasmia11,
  jasmia12,
  jasmia13,
];

export default jasmia;
