import rukolavanya1 from "./15.webp";
import rukolavanya2 from "./16.webp";
import rukolavanya3 from "./17.webp";
import rukolavanya4 from "./18.webp";
import rukolavanya5 from "./19.webp";
import rukolavanya6 from "./20.webp";
import rukolavanya7 from "./21.webp";

const rukolavanya = [
  rukolavanya1,
  rukolavanya2,
  rukolavanya3,
  rukolavanya4,
  rukolavanya5,
  rukolavanya6,
  rukolavanya7,
];

export default rukolavanya;
