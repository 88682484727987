import Kana1 from "./kana (1).webp";
import Kana2 from "./kana (2).webp";
import Kana3 from "./kana (3).webp";
import Kana4 from "./kana (4).webp";
import Kana5 from "./kana (5).webp";
import Kana6 from "./kana (6).webp";
import Kana7 from "./kana (7).webp";
import Kana8 from "./kana (8).webp";
import Kana9 from "./kana (9).webp";
import Kana10 from "./kana (10).webp";
import Kana11 from "./kana (11).webp";

const kana = [
  Kana1,
  Kana2,
  Kana3,
  Kana4,
  Kana5,
  Kana6,
  Kana7,
  Kana8,
  Kana9,
  Kana10,
  Kana11,
];

export default kana;
