import React from "react";
import "./Gallery.scss";
import Slider from "react-slick";
import galleryimage from "../../media/Gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Gallery = () => {
  const promogalery1 = [
    "Cluster Lavesh",
    "Cluster Samata",
    "Cluster Arana",
    "Cluster Adara",
  ];
  const promogalery2 = [
    "Cluster Asera One West",
    "Cluster Asera One East",
    "Cluster Asera One South",
    "Cluster Asia Tropis",
  ];
  const gallerybutton = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287782793628&text=Halo+Romy+mau+info+rumah+(Ready%20Siap%20Huni)https://kotaharapanindah.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="gallerycontainer">
      <div id="galery" className="gallerytitle">
        Gallery Rumah Ready Harapan Indah
      </div>
      <div className="gallerycarousel">
        <div className="center">
          <Slider {...settings}>
            {galleryimage.map((item, index) => (
              <img
                className="cluster-image"
                key={index}
                src={item}
                alt={`galery ${index + 1}`}
              />
            ))}
          </Slider>
        </div>
      </div>
      <div className="promopointg">
        <div className="poinitem">
          <div className="poinleft">
            {promogalery1.map((item, index) => (
              <div className="item">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="#086d44"
                  size="lg"
                />
                &nbsp; &nbsp;
                {item}
              </div>
            ))}
          </div>
          <div className="poinleft">
            {promogalery2.map((item, index) => (
              <div className="item">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="#086d44"
                  size="lg"
                />
                &nbsp; &nbsp;
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="gallerybutton">
        <div className="galleryleft">
          <button onClick={gallerybutton} className="button">
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;Informasi Cluster Siap Huni
          </button>
        </div>
      </div>
      <hr className="divider" />
    </div>
  );
};

export default Gallery;
