import viola1 from "./15.webp";
import viola2 from "./3.webp";
import viola3 from "./4.webp";
import viola4 from "./5.webp";
import viola5 from "./6.webp";
import viola6 from "./7.webp";
import viola7 from "./8.webp";
import viola8 from "./11.webp";
import viola9 from "./12.webp";

const viola = [
  viola1,
  viola2,
  viola3,
  viola4,
  viola5,
  viola6,
  viola7,
  viola8,
  viola9,
];

export default viola;
