import teradamai1 from "./thompson (1).webp";
import teradamai2 from "./thompson (2).webp";
import teradamai3 from "./thompson (3).webp";
import teradamai4 from "./thompson (4).webp";
import teradamai5 from "./thompson (5).webp";
import teradamai6 from "./thompson (6).webp";
import teradamai7 from "./thompson (7).webp";
import teradamai8 from "./thompson (8).webp";
import teradamai9 from "./thompson (9).webp";
import teradamai10 from "./thompson (10).webp";
import teradamai11 from "./thompson (11).webp";
import teradamai12 from "./thompson (12).webp";

const thompson = [
  teradamai1,
  teradamai2,
  teradamai3,
  teradamai4,
  teradamai5,
  teradamai6,
  teradamai7,
  teradamai8,
  teradamai9,
  teradamai10,
  teradamai11,
  teradamai12,
];

export default thompson;
