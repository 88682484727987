import React, { useState, useEffect } from "react";
import "./Hero.scss";

const Hero = ({ desktopImage, mobileImage }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="hero-container">
      <img
        src={isMobile ? mobileImage : desktopImage}
        className="heroimage"
        alt="Hero"
      />
    </div>
  );
};

export default Hero;
