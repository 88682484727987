import React from "react";
import "./Surrounding.scss";
import surroundingimg from "../../media/Surrounding";
import Slider from "react-slick";
import surroundingtitle from "../../media/Surrounding/WhatsApp-Image-2024-03-08-at-4.40.50-PM.jpeg";
const Surrounding = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="surroundingcontainer">
      <div className="surroundingtitle">SURROUNDING AREA</div>
      <div className="surroundingimgtitle">
        <img className="surroundingimg" src={surroundingtitle} />
      </div>
      <div className="surroundingcarousel">
        <div className="center">
          <Slider {...settings}>
            {surroundingimg.map((item, index) => (
              <img
                className="cluster-image"
                key={index}
                src={item}
                alt={`galery ${index + 1}`}
              />
            ))}
          </Slider>
        </div>
      </div>
      <hr className="divider" />
    </div>
  );
};

export default Surrounding;
