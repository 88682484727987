import React from "react";
import "./About.scss";
import logo from "../../media/khilogo.png";
import ReactReadMoreReadLess from "react-read-more-read-less";

const About = () => {
  const text =
    "Kota Harapan Indah adalah sebuah kawasan permukiman yang terletak di antara Cakung, Jakarta Timur. Kota Harapan Indah merupakan kota mandiri yang direncanakan dengan luas lahan sekitar 2.200 hektar Di kawasan ini terdapat supermarket, sekolah, klub olahraga, beberapa gedung perkantoran, dan beberapa cluster yang dipasarkan mencakup Asia Tropis, Arana, Asera Nishi, Adara, Lesha, Lavesh, Kana, Nismara, Tera Damai, Segara City, dan rencana untuk mengembangkan pusat perbelanjaan baru Terdapat juga beberapa fasilitas seperti Ace Hardware, Santika Premier Hotel, Carrefour, Lotte Mart, Courts Megastore, Esa Unggul University, BPK Penabur School, Sekolah Al Azhar, Transera Waterpark, Gramedia Book Store, RS Ananda Harapan Indah, RS Citra Harapan, Transera water-park, Harapan Indah sports club, apartment, dan Mall Harapan Indah";

  return (
    <div id="about" className="aboutcontainer">
      <div className="aboutcenter">
        <div className="aboutlogo">
          <img className="logo" src={logo} alt="kotaharapanindah" />
          <div className="logodes">Timur Jakarta, Bekasi</div>
        </div>
        <div className="abouttitle">Kawasan Mandiri Kota Harapan Indah</div>
        <div className="logodes">Timur Jakarta, Bekasi</div>
        <div className="logoprice">Rp. 2 Juta - 16 Juta</div>
        <div className="aboutsubtitle">
          <ReactReadMoreReadLess
            charLimit={484}
            readMoreText={"Read more ▼"}
            readLessText={"Read less ▲"}
          >
            {text}
          </ReactReadMoreReadLess>
        </div>
      </div>
    </div>
  );
};

export default About;
