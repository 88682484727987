import React from "react";
import Hero from "../../section/hero/Hero";
import mobileImage from "../../media/Hero/khi-3.png";
import desktopImage from "../../media/Hero/khi.png";
import About from "../../section/about/About";
import Promo from "../../section/promo/Promo";
import Product from "../../section/product/Product";
import Gallery from "../../section/gallery/Gallery";
import Surrounding from "../../section/surrounding/Surrounding";
import Lokasi from "../../section/lokasi/Lokasi";
import Footer2 from "../../section/footer2/footer2";
import ScrollToHashElement from "../ScrollToHashElement.js";

const Home = () => {
  return (
    <div>
      <ScrollToHashElement />
      <Hero mobileImage={mobileImage} desktopImage={desktopImage} />
      <About />
      <Promo />
      <Product />
      <Gallery />
      <Surrounding />
      <Lokasi />
      <Footer2 />
    </div>
  );
};

export default Home;
