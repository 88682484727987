import nishi1 from "./H-3.webp";
import nishi2 from "./9-4.webp";
import nishi3 from "./10-4.webp";
import nishi4 from "./11-3.webp";
import nishi5 from "./12-1.webp";
import nishi6 from "./13-1.webp";
import nishi7 from "./14.webp";
import nishi8 from "./15.webp";
import nishi9 from "./16.webp";

const nishi = [
  nishi1,
  nishi2,
  nishi3,
  nishi4,
  nishi5,
  nishi6,
  nishi7,
  nishi8,
  nishi9,
];

export default nishi;
