import React from "react";
import "./Lokasi.scss";
import map from "../../media/map.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Lokasi = () => {
  const mapbutton = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287782793628&text=Halo+Romy+mau+info+rumah+(Lokasi)https://kotaharapanindah.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="lokasi" className="lokasicontainer">
      <div className="lokasimap">
        <img alt="maphi" className="gambarmap" src={map} />
      </div>
      <div className="lokasibutton">
        <div className="center">
          <button onClick={mapbutton} className="button">
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
      <div className="lokasititle">Lokasi :</div>
      <ul className="listlokasi">
        <li>1 Menit ke New Summarecon</li>
        <li>5 Menit ke Pintu Tol JORR</li>
        <li>5 Menit ke AEON Mall dan IKEA</li>
        <li>15 Menit ke Kelapa Gading</li>
        <li>35 Menit ke Bandara</li>
      </ul>
    </div>
  );
};

export default Lokasi;
