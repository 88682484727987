import teradamai1 from "./H-2.webp";
import teradamai2 from "./5-2.webp";
import teradamai3 from "./6-1.webp";
import teradamai4 from "./7-2.webp";
import teradamai5 from "./8-2.webp";
import teradamai6 from "./9-1.webp";
import teradamai7 from "./10-1.webp";
import teradamai8 from "./11-1.webp";

const teradamai = [
  teradamai1,
  teradamai2,
  teradamai3,
  teradamai4,
  teradamai5,
  teradamai6,
  teradamai7,
  teradamai8,
];

export default teradamai;
